import React from 'react';

const NotFound = () => {
    return (
        <div className='notfound-wrapper'>
            <div className='notfound-image'></div>
            <div className='error-msg'>
                Opps, <br />
                Something went wrong.
            </div>
            <div className='scan-error'>
                Please scan the qr code again or talk to helpdesk.
            </div>
        </div>
    );
};

export default NotFound;
