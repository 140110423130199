import './stylesheet/global.scss';
import Gold from './templates/W-Maldives/Gold';
import Platinum from './templates/W-Maldives/Platinum';
import Titanium from './templates/W-Maldives/Titanium';
import { useHotelDetails } from './context/HotelContext';

const Layout = () => {
    const { isHotelDetailsLoading, hotelDetails } = useHotelDetails();
    if (isHotelDetailsLoading) return <p className='loading'>Loading...</p>;
    const payload = {
        isHotelDetailsLoading,
        hotelDetails,
    };
    const componentManifest = Object.freeze({
        GOLD: <Gold {...payload} />,
        TITANIUM: <Titanium {...payload}/>,
        PLATINUM: <Platinum {...payload}/>,
    });

    const template =
        hotelDetails && hotelDetails.template_type
            ? hotelDetails.template_type
            : '';
    const selectedComponent =
        componentManifest[template as keyof typeof componentManifest];
    if (!selectedComponent) return <p>No template selected</p>;
    return <div className='App'>{selectedComponent}</div>;
};

export default Layout;
