import React, { useContext, useState, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HotelDetailsContentType, HotelDetailsType } from '../@types';
import { getCookie, setCookie } from '../helper/_cookieHelper';
import { fetchTemplateContent } from '../services/apiservice';

interface HotelDetailsContextType {
    isHotelDetailsLoading: boolean;
    hotelDetails: {
        [key: string]: any;
    };
}
interface HotelContextProviderProps {
    children?: ReactNode;
    // any props that come into the component
}
const HotelContext = React.createContext<Partial<HotelDetailsContextType>>({});
export const useHotelDetails = () => useContext(HotelContext);

const HotelContextProvider = ({ children }: HotelContextProviderProps) => {
    const [isHotelDetailsLoading, setIsHotelDetailsLoading] = useState(false);
    const [hotelDetails, setHotelDetails] = useState({});
    const navigate = useNavigate();
    useEffect(() => {
        const templateID = getCookie('__templateID');
        if (templateID) {
            getTemplateContent(templateID);
        }
    }, []);

    const getTemplateContent = async (id: string) => {
        setIsHotelDetailsLoading(true);
        try {
            const response = await fetchTemplateContent(id);
            if (response.status === 200) {
                const result = await response.json();
                if (result.response && Object.keys(result.response).length) {
                    setHotelDetails(result.response);
                    navigate('/home', { replace: true });
                }
            }
        } catch (error) {
        } finally {
            setIsHotelDetailsLoading(false);
        }
    };
    let valuePayload = {
        isHotelDetailsLoading: isHotelDetailsLoading,
        hotelDetails: hotelDetails,
    };
    return (
        <HotelContext.Provider value={valuePayload}>
            {children}
        </HotelContext.Provider>
    );
};

export default HotelContextProvider;
