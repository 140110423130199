import './stylesheet/global.scss';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import Layout from './Layout';
import RequireAuth from './auth/RequireAuth';
import NotFound from './pages/NotFound';
import { useEffect } from 'react';
import { getCookie, setCookie } from './helper/_cookieHelper';

function App() {
    const location = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        if (location.pathname.includes('welcome')) {
            let splitedLocation = location.pathname.split('/');
            if (splitedLocation.length === 3) {
                if (
                    splitedLocation[1] === 'welcome' &&
                    splitedLocation[2].length > 20
                ) {
                    setCookie('__templateID', splitedLocation[2], 300);
                    navigate('/home', { replace: true });
                }
            }
        }
    }, []);
    return (
        <Routes>
            <Route
                path='/'
                element={
                    <RequireAuth>
                        <Layout />
                    </RequireAuth>
                }
            >
                <Route index element={<Layout />} />
                <Route path='/home' element={<Layout />} />
            </Route>
            <Route path='/not-found' element={<NotFound />} />
            {/* <Route path='*' element={<NotFound />} /> */}
        </Routes>
    );
}

export default App;
