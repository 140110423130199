const baseURL =
    process.env.REACT_APP_NODE_ENV === 'production'
        ? `https://prod-hmg.valet2you.in`
        : 'https://dev-hmg.valet2you.in';

export const fetchTemplateContent = (id: string) => {
    const response = fetch(baseURL + `/template-content/${id}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response;
};
