import { TemplateProps } from '../../@types';
import { appLinkClickHandler } from '../../helper/_commonHelper';

const Gold = (props: TemplateProps) => {
    const { hotelDetails } = props;
    return (
        <div className='template-container gold'>
            <div className='template-hero-banner center-top'>
                <div className='title-welcome'>
                <i>Welcome to</i><br />
                  <b>{hotelDetails?.content?.welcomeTitle}</b>
                    {/* 
                    <br />
                    <b>W Maldives</b> */}
                </div>
                <div className='guest-name'>
                    {hotelDetails?.content?.guestName}
                </div>
                <div className='welcome-msg'>
                    {hotelDetails?.content?.description}
                </div>
            </div>
            <div className='template-sponsers'>
                <div className='tagline'>Go there with </div>
                <div
                    className='sponsers-logo'
                    onClick={() =>
                        appLinkClickHandler(
                            'https://mobile-app.marriott.com/en-us',
                            '_blank'
                        )
                    }
                ></div>
                <div className='subtitle'>DISCOVER, BOOK, BE Rewarded</div>
                <div className='app-links'>
                    <div
                        className='app-store'
                        onClick={() =>
                            appLinkClickHandler(
                                'https://apps.apple.com/us/app/marriott-international/id455004730',
                                '_blank'
                            )
                        }
                    ></div>
                    <div
                        className='play-store'
                        onClick={() =>
                            appLinkClickHandler(
                                'https://play.google.com/store/apps/details?id=com.marriott.mrt&hl=en&gl=US',
                                '_blank'
                            )
                        }
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default Gold;
